<template>
  <div>
    <a-card title="分拣记录">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" allowClear @change="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="运单号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.production_line" placeholder="生产线" allowClear style="width: 100%" @change="search">
            <a-select-option value="a">名创 A</a-select-option>
            <a-select-option value="b">名创 B</a-select-option>
            <a-select-option value="c">名创 C</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.con_number" placeholder="con号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.sorting_status" placeholder="状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="normal" value="normal">正常</a-select-option>
            <a-select-option key="duplicate_sorting" value="duplicate_sorting">重复分拣</a-select-option>
            <a-select-option key="no_barcode" value="no_barcode">无条码</a-select-option>
            <a-select-option key="multiple" value="multiple">多条码</a-select-option>
            <a-select-option key="not_entered" value="not_entered">包裹未录入</a-select-option>
            <a-select-option key="no_weight" value="no_weight">无重量</a-select-option>
            <a-select-option key="no_volume" value="no_volume">无体积</a-select-option>
            <a-select-option key="max_quantity" value="max_quantity">数量超出</a-select-option>
            <a-select-option key="max_weight" value="max_weight">重量超出</a-select-option>
            <a-select-option key="not_met" value="not_met">无分拣道口</a-select-option>
            <a-select-option key="no_con" value="no_con">无绑定CON</a-select-option>
            <a-select-option key="zero_car_code" value="zero_car_code">零小车码</a-select-option>
            <a-select-option key="not_found_car_code" value="not_found_car_code">无法获取小车码</a-select-option>
            <a-select-option key="interval_abnormal" value="interval_abnormal">包裹间距异常</a-select-option>
            <a-select-option key="measurement_anomaly" value="measurement_anomaly">包裹测量异常</a-select-option>
            <a-select-option key="package_interception" value="package_interception">包裹拦截</a-select-option>
            <a-select-option key="oversize_interception" value="oversize_interception">超限拦截</a-select-option>
            <a-select-option key="sensitive_interception" value="sensitive_interception">敏感品拦截</a-select-option>
            <a-select-option key="ocs_error" value="ocs_error">ocs 异常</a-select-option>
            <a-select-option key="lft_error" value="lft_error">lft 异常</a-select-option>
            <a-select-option key="dim_error" value="dim_error">dim 异常</a-select-option>
            <a-select-option key="unbind_con" value="unbind_con">人工取消绑定 CON</a-select-option>
            <a-select-option key="other" value="other">其他异常</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-popconfirm
              :title="`确定批量删除 ${pagination.total} 条数据吗? `"
              :diabled="batchDeleteLoading"
              @confirm="batchDelete"
            >
              <a-button type="danger" icon="delete" :loading="batchDeleteLoading">批量删除</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <a-col :span="24" style="width: 200px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
          </a-button-group>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ x: true }"
            @change="changeTable"
          >
            <template slot="sorting_status_display" slot-scope="value, item">
              <a-badge v-if="item.sorting_status === 'normal'" status="success" text="正常" />
              <a-badge v-else status="error" :text="`${item.sorting_status_display} ${item.interception_reason ?? ''}`" />
            </template>

            <div slot="sorting_image" slot-scope="value, item" style="width: 36px; height: 36px">
              <div v-if="item.sorting_image" style="cursor: pointer">
                <img :src="'data:image/jpg;base64,' + item.sorting_image" :preview="item.id" width="36" height="36" />
              </div>
            </div>
            <div slot="sorting_image2" slot-scope="value, item" style="width: 36px; height: 36px">
              <div v-if="item.sorting_image2" style="cursor: pointer">
                <img :src="'data:image/jpg;base64,' + item.sorting_image2" :preview="item.id" width="36" height="36" />
              </div>
            </div>
            <div slot="sorting_image3" slot-scope="value, item" style="width: 36px; height: 36px">
              <div v-if="item.sorting_image3" style="cursor: pointer">
                <img :src="'data:image/jpg;base64,' + item.sorting_image3" :preview="item.id" width="36" height="36" />
              </div>
            </div>
            <!-- <div slot="haikang_image" slot-scope="value, item" style="width: 36px; height: 36px">
              <div v-if="item.haikang_image" style="cursor: pointer">
                <img :src="'data:image/jpg;base64,' + item.haikang_image" :preview="item.id" width="36" height="36" />
              </div>
            </div> -->

            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @destroy="destroy" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="exportLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导出中, 请等待...</div>
    </a-modal>
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { sortingRecordList, sortingRecordBatchDelete, sortingRecordExport } from "@/apis/sorting";
import { sheinUploadTemplate, sheinUploadImport } from "@/apis/sorting";
import { removeItem } from "@/utils/functions";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: true,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "图片",
          dataIndex: "sorting_image",
          width: 60,
          scopedSlots: { customRender: "sorting_image" },
        },
        {
          title: "图片",
          dataIndex: "sorting_image2",
          width: 60,
          scopedSlots: { customRender: "sorting_image2" },
        },
        {
          title: "图片",
          dataIndex: "sorting_image3",
          width: 60,
          scopedSlots: { customRender: "sorting_image3" },
        },
        // {
        //   title: "动态秤图",
        //   dataIndex: "haikang_image",
        //   width: 80,
        //   scopedSlots: { customRender: "haikang_image" },
        // },
        {
          title: "运单号",
          dataIndex: "tracking_number",
          width: 160,
        },
        {
          title: "生产线",
          dataIndex: "production_line_display",
        },
        {
          title: "分拣规则",
          dataIndex: "sorting_rule_name",
          width: 120,
        },
        {
          title: "分拣道口",
          dataIndex: "crossing_name",
          width: 120,
        },
        {
          title: "con",
          dataIndex: "con_number",
          width: 160,
        },
        {
          title: "重量(kg)",
          dataIndex: "weight",
          width: 100,
        },
        {
          title: "长(mm)",
          dataIndex: "length",
          width: 100,
        },
        {
          title: "宽(mm)",
          dataIndex: "width",
          width: 100,
        },
        {
          title: "高(mm)",
          dataIndex: "height",
          width: 100,
        },
        {
          title: "体积(mm³)",
          dataIndex: "volume",
          width: 100,
        },
        {
          title: "分拣状态",
          dataIndex: "sorting_status_display",
          width: 160,
          scopedSlots: { customRender: "sorting_status_display" },
        },
        {
          title: "country",
          dataIndex: "country_code",
          width: 180,
        },
        {
          title: "station",
          dataIndex: "destination_location_id",
          width: 180,
        },
        {
          title: "airport",
          dataIndex: "airport_id",
          width: 180,
        },
        {
          title: "constime",
          dataIndex: "constime",
          width: 180,
        },
        {
          title: "ocs_error_code",
          dataIndex: "ocs_error_code",
          width: 120,
          customRender: (value) => {
            if (value === "0000") {
              return "正常";
            } else if (value === "0001") {
              return "Weight not Legal for Trade";
            } else if (value === "0003") {
              return "Package Distance Error";
            } else if (value === "0005") {
              return "Package Length Error";
            } else if (value === "0009") {
              return "External Fault";
            } else if (value === "0011") {
              return "Invalid Measurement";
            } else if (value === "0021") {
              return "Weight higher than max. legal Weight";
            } else if (value === "0041") {
              return "Weight lower than min. legal Weight";
            } else if (value === "0081") {
              return "Overload";
            } else if (value === "0101") {
              return "Underload";
            } else if (value === "0201") {
              return "Conveyor Speed changes within Measurement";
            } else if (value === "0401") {
              return "Conveyor Speed out of max. limit";
            } else if (value === "0801") {
              return "Alibi Storage at Scale failed";
            } else if (value === "1001") {
              return "Scale Data Format Error";
            } else if (value === "2001") {
              return "Invalid Checksum ( only at SIM2k)";
            } else if (value === "4001") {
              return "Invalid tare information (only at SIM2k)";
            } else {
              return value;
            }
          },
        },
        {
          title: "lft_flag",
          dataIndex: "lft_flag",
          width: 120,
          customRender: (value) => {
            if (value === "L") {
              return "符合";
            } else if (value === "N") {
              return "不符合";
            } else {
              return value;
            }
          },
        },
        {
          title: "dim_status_code",
          dataIndex: "dim_status_code",
          width: 180,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      exportLoading: false,
      importLoading: false,
      batchDeleteLoading: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.dataLoading = true;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      sortingRecordList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.search();
    },
    exportExcel() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.exportLoading = true;
      sortingRecordExport(searchForm)
        .then((response) => {
          exportExcel(response, "分拣记录列表");
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    batchDelete() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.batchDeleteLoading = true;
      sortingRecordBatchDelete(searchForm)
        .then(() => {
          this.$message.success("批量删除成功");
          this.list();
        })
        .finally(() => {
          this.batchDeleteLoading = false;
        });
    },
    downloadTemplate() {
      sheinUploadTemplate().then((response) => {
        exportExcel(response, "希音回传导入模板");
      });
    },
    importExcel(item) {
      const data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      sheinUploadImport(data)
        .then(() => {
          this.$message.success("导入成功");
          this.list();
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style scoped></style>
